import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import {
    Maybe,
    SanityImageCrop,
    SanityImageHotspot,
} from '../../graphql-types';

interface ImageWithCropAndHotspotProps extends GatsbyImageProps {
    hotspot?: Maybe<SanityImageHotspot> | undefined;
    crop?: Maybe<SanityImageCrop> | undefined;
}

export default function ImageWithCropAndHotspot({
    hotspot,
    crop,
    ...props
}: ImageWithCropAndHotspotProps) {
    const responsiveProps: any = {};
    if (crop) {
        const { left, right, top, bottom } = crop;
        const { width, height } = props.image;
        const croppedWidthPercent = 1 - (left || 0) - (right || 0);
        const croppedHeightPercent = 1 - (top || 0) - (bottom || 0);
        const croppedWidth = width * croppedWidthPercent;
        const croppedHeight = height * croppedHeightPercent;

        if (croppedWidthPercent !== 1 || croppedHeightPercent !== 1) {
            const ratio = croppedWidth / croppedHeight;
            responsiveProps.style = {
                aspectRatio: ratio,
            };
            responsiveProps.imgStyle = {
                width: `${croppedWidthPercent * 100}%`,
                height: `${croppedHeightPercent * 100}%`,
            };
            responsiveProps.objectFit = 'none';
        }
    }

    if (hotspot) {
        responsiveProps.objectPosition = [hotspot?.x, hotspot?.y]
            .map(value => (value ? `${(value * 100).toFixed(2)}%` : '100%'))
            .join(' ');
    }

    return <GatsbyImage {...responsiveProps} {...props} />;
}
