import { Box, Center, Container, Heading, Text } from '@chakra-ui/react';
import { SanityCategory } from '../../graphql-types';
import useLocale from '../lib/useLocale';
import ImageWithCropAndHotspot from './ImageWithCropAndHotspot';

interface CategoryHeaderProps {
    category: SanityCategory | null | undefined;
}

export default function CategoryHeader({ category }: CategoryHeaderProps) {
    const { translate, format } = useLocale();
    const title = category?.title
        ? translate(category?.title)
        : format('allProducts');
    const description = category?.description
        ? translate(category?.description)
        : '';

    const hasCover = !!category?.cover?.asset?.gatsbyImageData;

    return (
        <Box display="grid" h="33vh" mb={6}>
            {hasCover && (
                <>
                    <ImageWithCropAndHotspot
                        image={category?.cover?.asset?.gatsbyImageData}
                        hotspot={category?.cover?.hotspot}
                        crop={category?.cover?.crop}
                        alt={title}
                        style={{
                            gridArea: '1/1',
                        }}
                    />
                    <Box
                        position="relative"
                        h="100%"
                        w="100%"
                        bg="rgba(0,0,0,0.5)"
                        gridArea="1/1"
                    />
                </>
            )}
            <Container maxW="container.lg" gridArea="1/1" position="relative">
                <Center
                    h="full"
                    flexDirection="column"
                    borderBottom={hasCover ? '' : '1px solid'}
                    borderColor="primary.100"
                    textAlign="center"
                >
                    <Heading
                        fontWeight="bold"
                        size="3xl"
                        fontSize={{
                            base: '2xl',
                            sm: '3xl',
                            md: '4xl',
                            lg: '5xl',
                        }}
                        textTransform="uppercase"
                        color={hasCover ? 'primary.50' : 'primary.900'}
                    >
                        {title}
                    </Heading>
                    {!!description && (
                        <Text
                            mt={4}
                            color={hasCover ? 'primary.50' : 'gray.600'}
                            fontSize="lg"
                        >
                            {description}
                        </Text>
                    )}
                </Center>
            </Container>
        </Box>
    );
}
