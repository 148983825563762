import { Container, SimpleGrid, Box } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { useMemo } from 'react';
import { SanityCategory } from '../../graphql-types';
import CategoryCard from '../components/CategoryCard';
import CategoryHeader from '../components/CategoryHeader';

interface CategoriesTemplateProps {
    data: {
        categories: {
            nodes: Array<SanityCategory>;
        };
        category: SanityCategory;
    };
}

export default function CategoriesTemplate({ data }: CategoriesTemplateProps) {
    const { category, categories } = data;

    const filteredCategories = useMemo(
        () =>
            categories.nodes.filter(cat => {
                if (!category) return true;
                return cat.parents?.some(p => p?._id === category?._id);
            }),
        [categories, category]
    );
    return (
        <>
            {category ? <CategoryHeader category={category} /> : <Box h={12} />}
            <Container maxW="container.lg">
                <SimpleGrid
                    minChildWidth={{ base: '200px', md: '300px' }}
                    spacing={10}
                    pt={6}
                    pb={12}
                >
                    {filteredCategories.map(item => (
                        <CategoryCard
                            key={item.id}
                            category={item}
                            variant={2}
                        />
                    ))}
                </SimpleGrid>
            </Container>
        </>
    );
}

export const query = graphql`
    query CategoriesListQuery($category: String, $language: String!) {
        categories: allSanityCategory(
            filter: { image: { asset: { _id: { ne: null } } } }
        ) {
            nodes {
                ...SanityCategoryFragment
            }
        }
        category: sanityCategory(id: { eq: $category }) {
            ...SanityCategoryFragment
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            ...LocaleFragment
        }
    }
`;
